// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

require("topsail_webauthn")

require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Twitter Bootstrap and dependencies (incl. jQuery, since it's Bootstrap v4.x)
import "satellite_bootstrap/bootstrap.scss"
import {default as jQuery} from "jquery"
window.$ = jQuery;
window.jquery = jQuery;
window.jQuery = jQuery;
import 'popper.js'
import 'bootstrap'
